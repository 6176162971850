import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ChessComponent from "../components/ChessComponent";
import GameServer, { RoomJson } from "../models/GameServer";

function Room() {
  const { roomId } = useParams<"roomId">();
  const [room, setRoom] = useState<RoomJson>()
  useEffect(() => {
    GameServer.subscribeOnServerPutRoom((roomJson) => {
      setRoom(roomJson)
    })
    if (roomId) GameServer.getRoom(roomId)
  }, [])
  return room ? <ChessComponent room={room}/> : <></>
}

export default Room