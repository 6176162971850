import React from "react"
import Cookie from "../models/Cookie"
import LocaleLanguages from "../enum/LocaleLanguages";

export type User = {
  nickname: string,
  hash: string,
  balance: number,
  setBalance: (balance: number) => void,
  language: LocaleLanguages
}

const user: User = {
  nickname: Cookie.getNickname(),
  hash: Cookie.getPlayerHash(),
  balance: Cookie.getBalance(),
  setBalance: (balance: number) => Cookie.setBalance(balance),
  language: Cookie.getLanguage(),
}

const UserContext = React.createContext<User>(user)

export default UserContext
