import React, { useState, useContext, useEffect } from "react"
import { Button, Container, FloatingLabel, Form } from "react-bootstrap"
import { useNavigate } from 'react-router-dom'
import LocaleContext from "../context/LocaleContext"
import LocaleLanguages from "../enum/LocaleLanguages"
import UserContext from "../context/UserContext"
import Cookie from "../models/Cookie"
import GameServer, { PlayerJson } from "../models/GameServer"

function Login() {
  const user = useContext(UserContext)
  const locale = useContext(LocaleContext)
  const navigate = useNavigate()
  const [nickname, setNickname] = useState(user.nickname)
  const [language, setLanguage] = useState<LocaleLanguages>(Cookie.getLanguage())
  const onInputChange = (e: React.BaseSyntheticEvent) => {
    setNickname(e.target.value)
  }
  const onEnter = (e: React.BaseSyntheticEvent) => {
    try {
    e.preventDefault()
    Cookie.setNickname(nickname)
    user.nickname = Cookie.getNickname()
    user.hash = Cookie.getPlayerHash()
    GameServer.login(user.hash, user.nickname, Cookie.getLanguage())
    } catch (error) {
      if (error instanceof Error) {
        alert(error.message)
      } else {
        alert(JSON.stringify(error))
      }
    }
  }
  const onServerPutLogin = (playerJson: PlayerJson) => {
    user.setBalance(playerJson.balance)
    navigate('/rooms')
  }
  const onServerPutLoginError = (errorMessage: string) => {
    alert(locale.t(errorMessage))
  }
  const onLanguageChangeInput = (e: React.ChangeEvent<HTMLSelectElement>) => {
    let l: LocaleLanguages = e.target.value === LocaleLanguages.English ? LocaleLanguages.English : LocaleLanguages.Russian
    Cookie.setLanguage(l)
    setLanguage(l)
  }
  useEffect(() => {
    GameServer.connect()
    GameServer.subscribeOnServerPutLogin(onServerPutLogin)
    GameServer.subscribeOnServerPutLoginError(onServerPutLoginError)
    return () => {
      GameServer.unsubscribeOnServerPutLogin()
      GameServer.unsubscribeOnServerPutLoginError()
    }
  }, [])
  return (
    <Container>
      <h3 className="mt-3">{locale.t("What's your nickname?")}</h3>
      <Form.Group className="mb-3" controlId="formNickname">
        <Form.Label>{locale.t("Nickname")}</Form.Label>
        <Form.Control type="text" placeholder={locale.t("Nickname")} value={nickname} onChange={onInputChange} />
      </Form.Group>
      {/* <Form.Group> */}
      <FloatingLabel controlId="floatingSelect" label={locale.t("Choose your language")}>
        <Form.Select aria-label="Floating label select example" value={language} onChange={onLanguageChangeInput}>
          <option value="en">English</option>
          <option value="ru">Русский</option>
        </Form.Select>
      </FloatingLabel>
      {/* </Form.Group> */}
      <hr />
      <Button variant="primary" size="sm" role={"button"} onClick={onEnter}>{locale.t("Go")}</Button>
    </Container>
  )
}

export default Login