import * as PIXI from 'pixi.js'
import FigureColor from '../../enum/FigureColor'
import Figure from '../../containers/Figure'
import { FigureJson } from '../../models/GameServer'

export default class EatedFigures extends PIXI.Container {

  private _hideColor: FigureColor

  constructor(hideColor: FigureColor) {
    super()
    this._hideColor = hideColor
    this.setEatedFigures([])
  }

  public setEatedFigures(eatedFigures: FigureJson[]) {
    this.removeChildren()
    this._drawBG()
    eatedFigures
      .filter(ef => ef.color !== this._hideColor)
      .forEach((ef, i) => {
        const figure = new Figure(ef.color, ef.type)
        figure.anchor.set(0, 0.5)
        figure.scale.set(0.08)
        figure.x = 15 + 35 * i
        figure.y = 70 / 2
        this.addChild(figure)
      })
  }

  private _drawBG() {
    const painter = new PIXI.Graphics()
    painter.beginFill(0xffffff)
    painter.drawRoundedRect(0, 0, 500, 80, 15)
    painter.endFill()
    this.addChild(painter)
  }

}