import * as PIXI from 'pixi.js'

export default class Timer extends PIXI.Container {

  private _surrenderButton: PIXI.Graphics
  private _surrenderButtonText: PIXI.Text
  private _seconds: number = 0

  constructor() {
    super()
    this._surrenderButton = new PIXI.Graphics()
    this._surrenderButton.beginFill(0xffffff)
    this._surrenderButton.drawRoundedRect(0, 0, 200, 70, 15)
    this._surrenderButton.endFill()
    this.addChild(this._surrenderButton)
    this._surrenderButtonText = new PIXI.Text("00:00", {fontFamily : 'Arial', fontSize: 32, fill : 0x0, align : 'center'})
    this._surrenderButtonText.x = 100
    this._surrenderButtonText.y = 70 / 2
    this._surrenderButtonText.anchor.set(0.5, 0.5)
    this.addChild(this._surrenderButtonText)
  }

  public setTime(seconds: number) {
    this._seconds = Math.round(seconds)
    const m = this._seconds / 60
    const s = this._seconds % 60
    const mm = this._numToDoubleNumText(m)
    const ss = this._numToDoubleNumText(s)
    this._surrenderButtonText.text =  mm + ':' + ss
  }
  private _numToDoubleNumText(n: number): string {
    if (n <= 0) return '00'
    const nText = Math.floor(n).toString()
    return nText.length === 1 ? '0' + nText : nText
  }

}