import Area from "../containers/Area"
import Board from "../containers/Board"

export default class FigureCoordinate {

  public static getX(coordinateX: number) {
    return Board.padding + (Area.size * coordinateX)
  }
  public static getY(coordinateY: number, rotate: boolean = false) {
    const realCoordinateY = rotate ? this.getReversedCoordinate(coordinateY) : coordinateY
    return Board.padding + (Area.size * realCoordinateY)
  }
  public static getReversedCoordinate(n: number) {
    return 7 - n
  }

}