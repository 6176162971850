import { useContext } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import FindRoom from "../components/FindRoom";
import LocaleContext from "../context/LocaleContext";
import UserContext from "../context/UserContext";

function Rooms() {
  const user = useContext(UserContext)
  const locale = useContext(LocaleContext)
  const navigate = useNavigate()
  const createRoom = () => {
    navigate('/create-room')
  }
  const logout = () => {
    navigate('/')
  }
  return (
    <Container>
    <Form>
      <h3 className="mt-3">{locale.t('Rooms')}</h3>
      <p>{locale.t('Welcome')}, {user.nickname}. {locale.t('Your balance')}: ${user.balance}</p>
      <FindRoom/>
      <div className="d-grid gap-2">
        <Button variant="primary" size="sm" role={"button"} onClick={createRoom}>{locale.t('Create game')}</Button>
        <Button variant="secondary" size="sm" role={"button"} onClick={logout}>{locale.t('Logout')}</Button>
      </div>
    </Form>
    </Container>
  )
}

export default Rooms