import { OrderState } from "./ColumnSortableHeader"

function FilterButton(props: { title: string, state: OrderState, onClick: () => void }) {
  return (
    <div role={"button"} onClick={() => props.onClick()}>
      <span>{props.title} </span>
      <i className={"bi fs-5 bi-caret-down" + (props.state === OrderState.Ascendant ? "-fill text-danger" : "")}></i>
      <i className={"bi fs-5 bi-caret-up" + (props.state === OrderState.Descendant ? "-fill text-danger" : "")}></i>
    </div>
  )
}

export default FilterButton