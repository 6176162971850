import * as PIXI from 'pixi.js'
import { Locale } from '../context/LocaleContext'
import { User } from '../context/UserContext'
import FigureColor from '../enum/FigureColor'
import { FigureJson, RoomJson } from '../models/GameServer'
import EatedFigures from './PlayerPanel/EatedFigures'
import Timer from './PlayerPanel/TImer'

export default class PlayerPanel extends PIXI.Container {

  public static padding = 70
  private _user: User
  private _locale: Locale
  private _eatedFigures: EatedFigures
  private _timer: Timer

  constructor(room: RoomJson, user: User, locale: Locale, panelOfPlayerColor: FigureColor) {

    super()

    this.name  = "player_panel_" + panelOfPlayerColor
    this.width = 1000
    this.height = 250
    this._user = user
    this._locale = locale
    this._eatedFigures = new EatedFigures(panelOfPlayerColor)
    this._eatedFigures.y = 0
    this.addChild(this._eatedFigures)

    this._timer = new Timer()
    this._timer.x = 800 - 20
    this.addChild(this._timer)

  }

  public setTime(seconds: number) {
    this._timer.setTime(seconds)
  }

  public setEatedFigures(figures: FigureJson[]) {
    this._eatedFigures.setEatedFigures(figures)
  }

  destroy() {}

}