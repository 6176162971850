import * as PIXI from 'pixi.js'
import FigureCoordinate from '../models/FigureCoordinate'
import Area from './Area'
import { gsap } from 'gsap'

export default class LightLayer extends PIXI.Container {

  constructor() {

    super()

    for (let y = 0; y < 8; y++) {
      for (let x = 0; x < 8; x++) {
        const area = new Area()
        area.name = LightLayer._generateName(x, y)
        area.x = FigureCoordinate.getX(x)
        area.y = FigureCoordinate.getY(y)
        area.alpha = 0
        area.renderable = false
        this.addChild(area)
      }
    }

  }

  public select(x: number, y: number) {
    const areaName = LightLayer._generateName(x, y)
    const area = this.getChildByName(areaName)
    area.renderable = true
    gsap.to(area, { alpha: 0.4, duration: 0.2 })
  }

  public unselect(x: number, y: number) {
    const areaName = LightLayer._generateName(x, y)
    const area = this.getChildByName(areaName)
    area.alpha = 0
    area.renderable = false
  }

  private static _generateName(x: number, y: number) {
    return `light_layer_${x}_${y}`
  }

}