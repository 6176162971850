import * as PIXI from 'pixi.js'
import { Locale } from '../context/LocaleContext'
import { User } from '../context/UserContext'
import GameServer, { FigureJson, RoomJson } from '../models/GameServer'
import Board from './Board'
import BetPanel from './BetPanel'
import PlayerPanel from './PlayerPanel'
import { Socket } from 'socket.io-client'
import ChessClockStatus from '../enum/ChessClockStatus'
import FigureColor from '../enum/FigureColor'

export default class Chess extends PIXI.Container {

  public board: Board
  private _betPanel: BetPanel
  private _enemyPanel: PlayerPanel
  private _myPanel: PlayerPanel
  private _socket: Socket
  private _status: ChessClockStatus
  private _whiteTimerSeconds: number = 0
  private _blackTimerSeconds: number = 0
  private _switchedTimerColor: FigureColor = FigureColor.White

  constructor(room: RoomJson, user: User, locale: Locale) {

    super()

    this._socket = GameServer.getInstance()
    const me = room.chessPlayers.find(p => p.player.hash === user.hash)
    const enemy = room.chessPlayers.find(p => p.player.hash !== user.hash)

    this._betPanel = new BetPanel(room, user, locale)
    this._betPanel.y = 0
    this._betPanel.on('surrender', () => {
      this._socket.emit('clientSurrender', room.id, me!.player.hash)
    })
    this.addChild(this._betPanel)
    this._enemyPanel = new PlayerPanel(room, user, locale, enemy!.color)
    this._enemyPanel.y = 250 - 70 - 20
    this.addChild(this._enemyPanel)
    this.board = new Board(room, user, locale)
    this.board.y = 250
    this.addChild(this.board)
    this._myPanel = new PlayerPanel(room, user, locale, me!.color)
    this._myPanel.y = 1250 + 20
    this.addChild(this._myPanel)

    this._enemyPanel.setEatedFigures(room.chess.eatedFigures)
    this._myPanel.setEatedFigures(room.chess.eatedFigures)
    this._socket.on('serverPutEatedFigure', (figure: FigureJson, figures: FigureJson[]) => {
      this.board.figuresLayer.removeFigure(figure.x, figure.y)
      this._enemyPanel.setEatedFigures(figures)
      this._myPanel.setEatedFigures(figures)
    })
    //
    this._status = ChessClockStatus.Ready
    this._socket = GameServer.getInstance()
    this._socket.on('timerChange', (status: ChessClockStatus, switchedToColor: FigureColor, switchedAtMoment: number, white: { id: string, seconds: number }, black: { id: string, seconds: number }) => {
      this._status = status
      const currentTime = Date.now() / 1000
      const networkDiff = currentTime - switchedAtMoment
      this._whiteTimerSeconds = white.seconds - networkDiff
      this._blackTimerSeconds = black.seconds - networkDiff
      this._switchedTimerColor = switchedToColor
    })
    this._socket.emit('clientGetTimerState', room.id)
    setInterval(() => {
      if (this._status !== ChessClockStatus.Started) return
      switch (this._switchedTimerColor) {
        case FigureColor.White:
          this._whiteTimerSeconds--
          break;
        case FigureColor.Black:
          this._blackTimerSeconds--
          break;
      }
      this._enemyPanel.setTime(me!.color === FigureColor.White ? this._blackTimerSeconds : this._whiteTimerSeconds)
      this._myPanel.setTime(me!.color === FigureColor.White ? this._whiteTimerSeconds : this._blackTimerSeconds)
    }, 1000)
  }

  destroy() {
    this.removeChildren()
    this._socket.off('serverPutEatedFigure')
    this._socket.off('timerChange')
  }

}