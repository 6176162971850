import { io, Socket } from 'socket.io-client'
import GameStatus from '../enum/GameStatus'
import RoomStatus from '../enum/RoomStatus'
import BetStatus from '../enum/BetStatus'
import LocaleLanguages from '../enum/LocaleLanguages'
import Cookie from './Cookie'
import FigureColor from '../enum/FigureColor'
import FigureType from '../enum/FigureType'

export type BetJson = { betAmount: number, bettedAmount: number, players: PlayerJson[], maxPlayers: number, status: BetStatus}
export type RoomJson = { id: string, name: string, status: RoomStatus, playersReadiness: { nickname: string, isReady: boolean, lang: LocaleLanguages }[], playersCountCurrent: number, playersCountMax: number, description: string | null, bet: BetJson, minutes: number, deadline: number, chess: ChessJson, chessPlayers: ChessPlayerJson[] }
export type PlayerJson = { hash: string, nickname: string, connectionId: string, localelanguage: LocaleLanguages, balance: number }
export type FigureJson = { x: number, y: number, color: FigureColor, type: FigureType }
export type ChessJson = {
  turn: FigureColor,
  figures: FigureJson[],
  eatedFigures: FigureJson[],
  status: GameStatus,
  localeLanguage: LocaleLanguages
}

export type ChessPlayerJson = { player: PlayerJson, color: FigureColor, isAdmin: boolean, isReady: boolean }

export default class GameServer {
  private static _socket: Socket

  private static _onServerPutUpdateConnectionIdError: ((errorMessage: string) => void) | undefined
  private static _onServerPutLogin: ((playerJson: PlayerJson) => void) | undefined
  private static _onServerPutLoginError: ((errorMessage: string) => void) | undefined
  private static _onServerPutAvailableRooms: ((rooms: RoomJson[]) => void) | undefined
  private static _onServerPutAvailableRoomsError: ((errorMessage: string) => void) | undefined
  private static _onServerPutJoinedNewPlayer: ((playerJson: PlayerJson, roomJson: RoomJson) => void) | undefined
  private static _onServerPutChessPlayers: ((chessPlayers: ChessPlayerJson[]) => void) | undefined
  private static _onServerPutChessPlayersError: ((errorMessage: string) => void) | undefined
  private static _onServerPutRoom: ((roomJson: RoomJson) => void) | undefined
  private static _onServerPutRoomError: ((errorMessage: string) => void) | undefined
  private static _onServerPutRoomGameOver: ((roomJson: RoomJson, playerJson: PlayerJson, winner: PlayerJson | null) => void) | undefined
  private static _onServerPutRoomPlay: ((roomJson: RoomJson) => void) | undefined
  private static _onServerPutMove: ((roomJson: RoomJson, ax: number, ay: number, bx: number, by: number, withReplacingToQueen: boolean) => void) | undefined
  private static _onServerPutJoinToRoomError: ((errorMessage: string) => void) | undefined
  private static _onServerPutMessageReadinessError: ((errorMessage: string) => void) | undefined
  private static _onServerPutLeaveRoomError: ((errorMessage: string) => void) | undefined
  private static _onServerPutEnterKeyError: ((errorMessage: string) => void) | undefined
  private static _onServerPutCreateRoom: ((playerJson: PlayerJson, roomJson: RoomJson) => void) | undefined
  private static _onServerPutCreateRoomError: ((errorMessage: string) => void) | undefined
  private static _onServerPutLeavedPlayer: ((playerJson: PlayerJson, roomJson: RoomJson) => void) | undefined
  private static _onServerPutWrongWordTyped: ((roomId: string, hash: string, rowNum: number) => void) | undefined
  private static _onServerPutEatedFigure: ((figure: FigureJson, figures: FigureJson[]) => void) | undefined

  public static connect() {
    if (GameServer._socket) return
    const uri = process.env.NODE_ENV === 'development' ? 'wss://localhost:3001' : 'wss://api-v1.spiderx.io:3001'
    GameServer._socket = io(uri, { withCredentials: true, auth: { hash: Cookie.getPlayerHash()}})
    GameServer._socket.on("connect", () => {
      console.log('connect')
      // resubscribe after reconnection
      /*
      GameServer.subscribeOnServerPutUpdateConnectionIdError(GameServer._onServerPutUpdateConnectionIdError)
      GameServer.subscribeOnServerPutLogin(GameServer._onServerPutLogin)
      GameServer.subscribeOnServerPutLoginError(GameServer._onServerPutLoginError)
      GameServer.subscribeOnServerPutAvailableRooms(GameServer._onServerPutAvailableRooms)
      GameServer.subscribeOnServerPutAvailableRoomsError(GameServer._onServerPutAvailableRoomsError)
      GameServer.subscribeOnServerPutJoinedNewPlayer(GameServer._onServerPutJoinedNewPlayer)
      GameServer.subscribeOnServerPutJoinedNewPlayer(GameServer._onServerPutJoinedNewPlayer)
      GameServer.subscribeOnServerPutChessPlayers(GameServer._onServerPutChessPlayers)
      GameServer.subscribeOnServerPutChessPlayersError(GameServer._onServerPutChessPlayersError)
      GameServer.subscribeOnServerPutRoom(GameServer._onServerPutRoom)
      GameServer.subscribeOnServerPutRoomError(GameServer._onServerPutRoomError)
      GameServer.subscribeOnServerPutRoomGameOver(GameServer._onServerPutRoomGameOver)
      GameServer.subscribeOnServerPutRoomPlay(GameServer._onServerPutRoomPlay)
      GameServer.subscribeOnServerPutJoinToRoomError(GameServer._onServerPutJoinToRoomError)
      GameServer.subscribeOnServerPutLeaveRoomError(GameServer._onServerPutLeaveRoomError)
      GameServer.subscribeOnServerPutEnterKeyError(GameServer._onServerPutEnterKeyError)
      GameServer.subscribeOnServerPutCreateRoom(GameServer._onServerPutCreateRoom)
      GameServer.subscribeOnServerPutCreateRoomError(GameServer._onServerPutCreateRoomError)
      GameServer.subscribeOnServerPutLeavedPlayer(GameServer._onServerPutLeavedPlayer)
      GameServer.subscribeOnServerPutWrongWordTyped(GameServer._onServerPutWrongWordTyped)
      */
    })
    GameServer._socket.on('disconnect', (reason: Socket.DisconnectReason) => {
      console.log('disconnect', reason)
    })
  }

  public static getInstance() {
    GameServer.connect()
    return this._socket
  }

  public static login(hash: string, nickname: string, language: LocaleLanguages) {
    GameServer.connect()
    GameServer._socket.emit('clientLogin', hash, nickname, language)
  }
  public static getAvailableRooms() {
    GameServer.connect()
    GameServer._socket.emit('clientGetAvailableRooms')
  }
  public static createRoom(name: string, hash: string, maxPlayersCount: number, minutes: number, description: string | null, betAmount: number) {
    GameServer.connect()
    GameServer._socket.emit('clientCreateRoom', name, hash, maxPlayersCount, minutes, description, betAmount)
  }
  public static joinToRoom(roomId: string, hash: string) {
    GameServer.connect()
    GameServer._socket.emit('clientJoinToRoom', roomId, hash)
  }
  public static messageReadiness(roomId: string, hash: string, isReady: boolean) {
    GameServer.connect()
    GameServer._socket.emit('clientMessageReadiness', roomId, hash, isReady)
  }
  public static getRoom(roomId: string) {
    GameServer.connect()
    GameServer._socket.emit('clientGetRoom', roomId)
  }
  public static leaveRoom(roomId: string, hash: string) {
    GameServer.connect()
    GameServer._socket.emit('clientLeaveRoom', roomId, hash)
  }
  public static move(roomId: string, hash: string, ax: number, ay: number, bx: number, by: number) {
    GameServer.connect()
    GameServer._socket.emit('clientMove', roomId, hash, ax, ay, bx, by)
  }
  public static getChessPlayers(roomId: string, hash: string) {
    GameServer.connect()
    GameServer._socket.emit('clientGetChessPlayers', roomId, hash)
  }

  // subscriptions

  public static subscribeOnServerPutUpdateConnectionIdError(handler: typeof GameServer._onServerPutUpdateConnectionIdError) {
    if (handler === undefined) return
    GameServer._onServerPutUpdateConnectionIdError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutUpdateConnectionIdError', GameServer._onServerPutUpdateConnectionIdError)
  }
  public static unsubscribeOnServerPutUpdateConnectionIdError() {
    if (GameServer._onServerPutUpdateConnectionIdError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutUpdateConnectionIdError', GameServer._onServerPutUpdateConnectionIdError)
  }
  public static subscribeOnServerPutLogin(handler: typeof GameServer._onServerPutLogin) {
    if (handler === undefined) return
    GameServer._onServerPutLogin = handler
    GameServer.connect()
    GameServer._socket.on('serverPutLogin', GameServer._onServerPutLogin)
  }
  public static unsubscribeOnServerPutLogin() {
    if (GameServer._onServerPutLogin === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutLogin', GameServer._onServerPutLogin)
  }
  public static subscribeOnServerPutLoginError(handler: typeof GameServer._onServerPutLoginError) {
    if (handler === undefined) return
    GameServer._onServerPutLoginError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutLoginError', GameServer._onServerPutLoginError)
  }
  public static unsubscribeOnServerPutLoginError() {
    if (GameServer._onServerPutLoginError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutLoginError', GameServer._onServerPutLoginError)
  }
  public static subscribeOnServerPutAvailableRooms(handler: typeof GameServer._onServerPutAvailableRooms) {
    if (handler === undefined) return
    GameServer._onServerPutAvailableRooms = handler
    GameServer.connect()
    GameServer._socket.on('serverPutAvailableRooms', GameServer._onServerPutAvailableRooms)
  }
  public static unsubscribeOnServerPutAvailableRooms() {
    if (GameServer._onServerPutAvailableRooms === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutAvailableRooms', GameServer._onServerPutAvailableRooms)
  }
  public static subscribeOnServerPutAvailableRoomsError(handler: typeof GameServer._onServerPutAvailableRoomsError) {
    if (handler === undefined) return
    GameServer._onServerPutAvailableRoomsError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutAvailableRoomsError', GameServer._onServerPutAvailableRoomsError)
  }
  public static unsubscribeOnServerPutAvailableRoomsError() {
    if (GameServer._onServerPutAvailableRoomsError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutAvailableRoomsError', GameServer._onServerPutAvailableRoomsError)
  }
  public static subscribeOnServerPutJoinedNewPlayer(handler: typeof GameServer._onServerPutJoinedNewPlayer) {
    if (handler === undefined) return
    GameServer._onServerPutJoinedNewPlayer = handler
    GameServer.connect()
    GameServer._socket.on('serverPutJoinedNewPlayer', GameServer._onServerPutJoinedNewPlayer)
  }
  public static unsubscribeOnServerPutJoinedNewPlayer() {
    if (GameServer._onServerPutJoinedNewPlayer === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutJoinedNewPlayer', GameServer._onServerPutJoinedNewPlayer)
  }
  public static subscribeOnServerPutMessageReadinessError(handler: typeof GameServer._onServerPutMessageReadinessError) {
    if (handler === undefined) return
    GameServer._onServerPutMessageReadinessError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutMessageReadinessError', GameServer._onServerPutMessageReadinessError)
  }
  public static unsubscribeOnServerPutMessageReadinessError() {
    if (GameServer._onServerPutMessageReadinessError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutMessageReadinessError', GameServer._onServerPutMessageReadinessError)
  }
  public static subscribeOnServerPutChessPlayers(handler: typeof GameServer._onServerPutChessPlayers) {
    if (handler === undefined) return
    GameServer._onServerPutChessPlayers = handler
    GameServer.connect()
    GameServer._socket.on('serverPutChessPlayers', GameServer._onServerPutChessPlayers)
  }
  public static unsubscribeOnServerPutChessPlayers() {
    if (GameServer._onServerPutChessPlayers === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutChessPlayers', GameServer._onServerPutChessPlayers)
  }
  public static subscribeOnServerPutRoom(handler: typeof GameServer._onServerPutRoom) {
    if (handler === undefined) return
    GameServer._onServerPutRoom = handler
    GameServer.connect()
    GameServer._socket.on('serverPutRoom', GameServer._onServerPutRoom)
  }
  public static unsubscribeOnServerPutRoom() {
    if (GameServer._onServerPutRoom === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutRoom', GameServer._onServerPutRoom)
  }
  public static subscribeOnServerPutRoomError(handler: typeof GameServer._onServerPutRoomError) {
    if (handler === undefined) return
    GameServer._onServerPutRoomError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutRoomError', GameServer._onServerPutRoomError)
  }
  public static unsubscribeOnServerPutRoomError() {
    if (GameServer._onServerPutRoomError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutRoomError', GameServer._onServerPutRoomError)
  }
  public static subscribeOnServerPutRoomGameOver(handler: typeof GameServer._onServerPutRoomGameOver) {
    if (handler === undefined) return
    GameServer._onServerPutRoomGameOver = handler
    GameServer.connect()
    GameServer._socket.on('serverPutRoomGameOver', GameServer._onServerPutRoomGameOver)
  }
  public static unsubscribeOnServerPutRoomGameOver() {
    if (GameServer._onServerPutRoomGameOver === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutRoomGameOver', GameServer._onServerPutRoomGameOver)
  }
  public static subscribeOnServerPutRoomPlay(handler: typeof GameServer._onServerPutRoomPlay) {
    if (handler === undefined) return
    GameServer._onServerPutRoomPlay = handler
    GameServer.connect()
    GameServer._socket.on('serverPutRoomPlay', GameServer._onServerPutRoomPlay)
  }
  public static unsubscribeOnServerPutRoomPlay() {
    if (GameServer._onServerPutRoomPlay === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutRoomPlay', GameServer._onServerPutRoomPlay)
  }
  public static subscribeOnServerPutMove(handler: typeof GameServer._onServerPutMove) {
    if (handler === undefined) return
    GameServer._onServerPutMove = handler
    GameServer.connect()
    GameServer._socket.on('serverPutMove', GameServer._onServerPutMove)
  }
  public static unsubscribeOnServerPutMove() {
    if (GameServer._onServerPutMove === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutMove', GameServer._onServerPutMove)
  }
  public static subscribeOnServerPutJoinToRoomError(handler: typeof GameServer._onServerPutJoinToRoomError) {
    if (handler === undefined) return
    GameServer._onServerPutJoinToRoomError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutJoinToRoomError', GameServer._onServerPutJoinToRoomError)
  }
  public static unsubscribeOnServerPutJoinToRoomError() {
    if (GameServer._onServerPutJoinToRoomError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutJoinToRoomError', GameServer._onServerPutJoinToRoomError)
  }
  public static subscribeOnServerPutLeaveRoomError(handler: typeof GameServer._onServerPutLeaveRoomError) {
    if (handler === undefined) return
    GameServer._onServerPutLeaveRoomError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutLeaveRoomError', GameServer._onServerPutLeaveRoomError)
  }
  public static unsubscribeOnServerPutLeaveRoomError() {
    if (GameServer._onServerPutLeaveRoomError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutLeaveRoomError', GameServer._onServerPutLeaveRoomError)
  }
  public static subscribeOnServerPutEnterKeyError(handler: typeof GameServer._onServerPutEnterKeyError) {
    if (handler === undefined) return
    GameServer._onServerPutEnterKeyError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutEnterKeyError', GameServer._onServerPutEnterKeyError)
  }
  public static unsubscribeOnServerPutEnterKeyError() {
    if (GameServer._onServerPutEnterKeyError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutEnterKeyError', GameServer._onServerPutEnterKeyError)
  }
  public static subscribeOnServerPutCreateRoom(handler: typeof GameServer._onServerPutCreateRoom) {
    if (handler === undefined) return
    GameServer._onServerPutCreateRoom = handler
    GameServer.connect()
    GameServer._socket.on('serverPutCreateRoom', GameServer._onServerPutCreateRoom)
  }
  public static unsubscribeOnServerPutCreateRoom() {
    if (GameServer._onServerPutCreateRoom === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutCreateRoom', GameServer._onServerPutCreateRoom)
  }
  public static subscribeOnServerPutCreateRoomError(handler: typeof GameServer._onServerPutCreateRoomError) {
    if (handler === undefined) return
    GameServer._onServerPutCreateRoomError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutCreateRoomError', GameServer._onServerPutCreateRoomError)
  }
  public static unsubscribeOnServerPutCreateRoomError() {
    if (GameServer._onServerPutCreateRoomError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutCreateRoomError', GameServer._onServerPutCreateRoomError)
  }
  public static subscribeOnServerPutChessPlayersError(handler: typeof GameServer._onServerPutChessPlayersError) {
    if (handler === undefined) return
    GameServer._onServerPutChessPlayersError = handler
    GameServer.connect()
    GameServer._socket.on('serverPutChessPlayersError', GameServer._onServerPutChessPlayersError)
  }
  public static unsubscribeOnServerPutChessPlayersError() {
    if (GameServer._onServerPutChessPlayersError === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutChessPlayersError', GameServer._onServerPutChessPlayersError)
  }
  public static subscribeOnServerPutLeavedPlayer(handler: typeof GameServer._onServerPutLeavedPlayer) {
    if (handler === undefined) return
    GameServer._onServerPutLeavedPlayer = handler
    GameServer.connect()
    GameServer._socket.on('serverPutLeavedPlayer', GameServer._onServerPutLeavedPlayer)
  }
  public static unsubscribeOnServerPutLeavedPlayer() {
    if (GameServer._onServerPutLeavedPlayer === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutLeavedPlayer', GameServer._onServerPutLeavedPlayer)
  }
  public static subscribeOnServerPutWrongWordTyped(handler: typeof GameServer._onServerPutWrongWordTyped) {
    if (handler === undefined) return
    GameServer._onServerPutWrongWordTyped = handler
    GameServer.connect()
    GameServer._socket.on('serverPutWrongWordTyped', GameServer._onServerPutWrongWordTyped)
  }
  public static unsubscribeOnServerPutWrongWordTyped() {
    if (GameServer._onServerPutWrongWordTyped === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutWrongWordTyped', GameServer._onServerPutWrongWordTyped)
  }
  public static subscribeOnServerPutEatedFigure(handler: typeof GameServer._onServerPutEatedFigure) {
    if (handler === undefined) return
    GameServer._onServerPutEatedFigure = handler
    GameServer.connect()
    GameServer._socket.on('serverPutEatedFigure', GameServer._onServerPutEatedFigure)
  }
  public static unsubscribeOnServerPutEatedFigure() {
    if (GameServer._onServerPutEatedFigure === undefined) return
    GameServer.connect()
    GameServer._socket.off('serverPutEatedFigure', GameServer._onServerPutEatedFigure)
  }

}
