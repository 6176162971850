import * as PIXI from 'pixi.js'
import FigureColor from '../enum/FigureColor'
import FigureType from '../enum/FigureType'

export default class Figure extends PIXI.Sprite {

  constructor(figureColor: FigureColor, figureType: FigureType) {

    super()

    this.texture = PIXI.Texture.from(`/GameElements/Figures/${figureColor}/${figureColor}_${figureType}.png`)
    this.scale.set(0.17)
    this.anchor.set(0.5, 1)

  }

}