import gsap from 'gsap'
import * as PIXI from 'pixi.js'
import FigureCoordinate from '../models/FigureCoordinate'
import Figure from './Figure'
import FigureColor from './../enum/FigureColor'
import FigureType from './../enum/FigureType'
import { FigureJson } from '../models/GameServer'

export default class FiguresLayer extends PIXI.Container {

  private _rotate: boolean

  constructor(rotate: boolean, figures: FigureJson[]) {

    super()

    this._rotate = rotate

    figures.forEach(f => {
      const figure = new Figure(f.color, f.type)
      figure.name = this._generateFigureName(f.x, f.y)
      figure.x = FigureCoordinate.getX(f.x)
      figure.y = FigureCoordinate.getY(f.y, rotate)
      figure.zIndex = rotate ? FigureCoordinate.getReversedCoordinate(f.y) : f.y
      this.addChild(figure)
    })

    this.sortableChildren = true

  }

  public async move(ax: number, ay: number, bx: number, by: number) {
    const figureA = this.getChildByName(this._generateFigureName(ax, ay))
    if (!figureA) throw Error("Figure not exists")
    const figureB = this.getChildByName(this._generateFigureName(bx, by))
    await gsap.to(figureA, {
      x: FigureCoordinate.getX(bx),
      y: FigureCoordinate.getY(by, this._rotate),
      zIndex: this._rotate ? 7 - by : by,
      duration: 0.1
    })
    figureA.name = this._generateFigureName(bx, by)
    if (figureB) {
      this.removeChild(figureB)
    }
  }

  public removeFigure(x: number, y: number) {
    const figure = this.getChildByName(this._generateFigureName(x, y))
    this.removeChild(figure)
  }

  public replacePawnToQueen(x: number, y: number)
  {
    // remove pawn
    this.removeFigure(x, y)
    // add queen
    const blackOfficer = new Figure(FigureColor.Black, FigureType.Queen)
    blackOfficer.name = this._generateFigureName(x, y)
    blackOfficer.x = FigureCoordinate.getX(x)
    blackOfficer.y = FigureCoordinate.getY(y)
    blackOfficer.zIndex = y
    this.addChild(blackOfficer)
  }

  private _generateFigureName(x: number, y: number) {
    return `figure_${x}_${y}`
  }

}