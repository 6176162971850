import * as PIXI from 'pixi.js'

export default class Area extends PIXI.Sprite {

  public static size = 95

  constructor() {

    super()

    this.texture = PIXI.Texture.WHITE
    this.tint = 0x00ff00
    this.alpha = 0.4
    this.width = Area.size
    this.height = Area.size

  }

}