import * as PIXI from 'pixi.js'
import FigureCoordinate from '../models/FigureCoordinate'
import Area from './Area'

export default class SensorLayer extends PIXI.Container {

  private _lastSelection: { x: number, y: number } | undefined

  constructor() {

    super()

    for (let y = 0; y < 8; y++) {
      for (let x = 0; x < 8; x++) {
        const area = new Area()
        area.name = this._generateName(x, y)
        area.x = FigureCoordinate.getX(x)
        area.y = FigureCoordinate.getY(y)
        area.interactive = true
        area.renderable = false
        area.on('pointerdown', () => this._click(x, y))
        this.addChild(area)
      }
    }

  }

  private _click(x: number, y: number) {
    if (this._lastSelection) {
      if (!(this._lastSelection.x === x && this._lastSelection.y === y)) {
        this.emit('move', this._lastSelection.x, this._lastSelection.y, x, y)
      }
      this.emit('unselectArea', this._lastSelection.x, this._lastSelection.y)
      this._lastSelection = undefined
    } else {
      this.emit('selectArea', x, y)
      this._lastSelection = { x: x, y: y }
    }
  }

  private _generateName(x: number, y: number) {
    return `sensor_area_${x}_${y}`
  }

}