import { useState } from "react"

export enum OrderState {
  None = 0,
  Ascendant = 1,
  Descendant = 2,
}

function ColumnSortableHeader(props: { title: string, orderState: OrderState, onClick: () => void }) {
  return (
    <div role={"button"} onClick={() => props.onClick()}>
      <span>{props.title}</span>
      <i className={"bi bi-caret-down-fill " + (props.orderState !== OrderState.Ascendant ? "d-none" : "")}></i>
      <i className={"bi bi-caret-up-fill " + (props.orderState !== OrderState.Descendant ? "d-none" : "")}></i>
    </div>
  )
}

export default ColumnSortableHeader