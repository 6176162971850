import * as PIXI from 'pixi.js'
import { NavigateFunction } from 'react-router-dom'
import { io, Socket } from 'socket.io-client'
import { Locale } from '../context/LocaleContext'
import { User } from '../context/UserContext'
import FigureColor from '../enum/FigureColor'
import Cookie from '../models/Cookie'
import { PlayerJson, RoomJson } from '../models/GameServer'
import Area from './Area'

export default class BetPanel extends PIXI.Container {

  public static padding = 70
  private _user: User
  private _locale: Locale
  private _fund: PIXI.Text
  private _bet: PIXI.Text
  private _surrenderButton: PIXI.Graphics
  private _surrenderButtonText: PIXI.Text

  constructor(room: RoomJson, user: User, locale: Locale) {

    super()

    this.name  = "panel"
    this.width = 1000
    this.height = 250
    this._user = user
    this._locale = locale
    this._fund = new PIXI.Text("Фонд: $" + room.bet.bettedAmount, {fontFamily : 'Arial', fontSize: 32, fill : 0xffffff, align : 'left'})
    this._fund.x = 20
    this._fund.y = 20
    this.addChild(this._fund)
    this._bet = new PIXI.Text("Ставка: $" + room.bet.betAmount, {fontFamily : 'Arial', fontSize: 32, fill : 0xffffff, align : 'left'})
    this._bet.x = 20
    this._bet.y = 80
    this.addChild(this._bet)
    this._surrenderButton = new PIXI.Graphics()
    this._surrenderButton.beginFill(0xd3752e)
    this._surrenderButton.drawRoundedRect(800 - 20, 20, 200, 70, 15)
    this._surrenderButton.endFill()
    this._surrenderButton.interactive = true
    this._surrenderButton.on('pointerdown', () => {
      this.emit('surrender')
    })
    this.addChild(this._surrenderButton)
    this._surrenderButtonText = new PIXI.Text("Сдаться", {fontFamily : 'Arial', fontSize: 32, fill : 0xffffff, align : 'center'})
    this._surrenderButtonText.x = 800 + 100 - 20
    this._surrenderButtonText.y = 20 + (70 / 2)
    // this._surrenderButtonText.style.wordWrap = true
    // this._surrenderButtonText.style.wordWrapWidth = 200
    // this._surrenderButtonText.width = 200
    // this._surrenderButtonText.height = 70
    this._surrenderButtonText.anchor.set(0.5, 0.5)
    this.addChild(this._surrenderButtonText)

  }

  destroy() {
  }

}