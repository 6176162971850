import { Viewport } from 'pixi-viewport'
import * as PIXI from 'pixi.js'
import { useContext, useEffect, useRef, useState } from 'react'
import Chess from '../containers/Chess'
import LocaleContext, { Locale } from '../context/LocaleContext'
import UserContext, { User } from '../context/UserContext'
import { RoomJson } from '../models/GameServer'

// PIXI.settings.SCALE_MODE = PIXI.SCALE_MODES.NEAREST
// PIXI.settings.RESOLUTION = window.devicePixelRatio;
// PIXI.settings.ANISOTROPIC_LEVEL = 16
// PIXI.settings.ROUND_PIXELS = true
PIXI.settings.PRECISION_FRAGMENT = PIXI.PRECISION.HIGH
const app = new PIXI.Application()
const viewport = new Viewport({
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
  worldWidth: 1000,
  worldHeight: 1500,
  interaction: app.renderer.plugins.interaction,
})
app.stage.addChild(viewport)
app.resizeTo = window
const moveCameraToCenter = () => {
  console.log('resizing chess components...')
  viewport.fit()
  viewport.moveCenter(viewport.worldWidth / 2, viewport.worldHeight / 2)
}
window.onresize = moveCameraToCenter
moveCameraToCenter()

function ChessComponent(props: { room: RoomJson }) {
  const ref = useRef<HTMLDivElement>(null)
  const user = useContext<User>(UserContext)
  const locale = useContext<Locale>(LocaleContext)
  const [chess, setChess] = useState<Chess>()

  useEffect(() => {
    setChess(new Chess(props.room, user, locale))
  }, [])
  useEffect(() => {
    if (ref.current && chess) {
      ref.current.appendChild(app.view)
      viewport.addChild(chess)
      app.start()
    }
    return () => {
      if (ref.current && chess) {
        ref.current.removeChild(app.view)
        viewport.removeChild(chess)
        app.destroy()
      }
    }
  }, [ref.current, chess])

  return <div ref={ref}/>
}

export default ChessComponent