import * as PIXI from 'pixi.js'
import { Socket } from 'socket.io-client'
import { Locale } from '../context/LocaleContext'
import { User } from '../context/UserContext'
import FigureColor from '../enum/FigureColor'
import Cookie from '../models/Cookie'
import GameServer, { PlayerJson, RoomJson } from '../models/GameServer'
import Area from './Area'
import FiguresLayer from './FiguresLayer'
import LightLayer from './LightLayer'
import SensorLayer from './SensorLayer'

export default class Board extends PIXI.Sprite {

  public static padding = 70
  public lightLayer: LightLayer
  public figuresLayer: FiguresLayer
  public sensorLayer: SensorLayer
  private _socket: Socket
  private _user: User
  private _locale: Locale

  constructor(room: RoomJson, user: User, locale: Locale) {

    super()

    this.name = 'board'
    this.texture = PIXI.Texture.from('/GameElements/desk.png')
    this.width = 1000
    this.height = 1000
    this._user = user
    this._locale = locale

    const rotateBoard = room.chessPlayers.some(cp => cp.player.hash === Cookie.getPlayerHash() && cp.color === FigureColor.Black)

    this.lightLayer = new LightLayer()
    this.lightLayer.name = 'light_layer'
    this.addChild(this.lightLayer)

    this.figuresLayer = new FiguresLayer(rotateBoard, room.chess.figures)
    this.figuresLayer.name = 'figures_layer'
    this.addChild(this.figuresLayer)
    this.figuresLayer.x = Area.size / 2
    this.figuresLayer.y = Area.size -5

    this.sensorLayer = new SensorLayer()
    this.sensorLayer.name = 'sensor_layer'
    this.addChild(this.sensorLayer)
    this.sensorLayer.on('selectArea', (x: number, y: number) => this.lightLayer.select(x, y))
    this.sensorLayer.on('unselectArea', (x: number, y: number) => this.lightLayer.unselect(x, y))
    //
    this._socket = GameServer.getInstance()
    this.sensorLayer.on('move', (ax: number, ay: number, bx: number, by: number) => {
      this._socket.emit('clientMove', room.id, Cookie.getPlayerHash(), ax, rotateBoard ? 7 - ay : ay, bx, rotateBoard ? 7 - by : by)
    })
    this._socket.on('serverPutMove', (roomJson: RoomJson, ax: number, ay: number, bx: number, by: number, withReplacingToQueen: boolean) => {
      this._serverPutMove(roomJson, ax, ay, bx, by, withReplacingToQueen)
    })
    this._socket.on('serverPutRoomGameOver', (roomJson: RoomJson, playerJson: PlayerJson, winner: PlayerJson | null) => {
      this._serverPutRoomGameOver(roomJson, playerJson, winner)
    })

  }

  private _serverPutMove(roomJson: RoomJson, ax: number, ay: number, bx: number, by: number, withReplacingToQueen: boolean) {
    this.figuresLayer.move(ax, ay, bx, by).then(() => {
      if (withReplacingToQueen) {
        this.figuresLayer.replacePawnToQueen(bx, by)
      }
    })
  }

  private _serverPutRoomGameOver(roomJson: RoomJson, playerJson: PlayerJson, winner: PlayerJson | null) {
    if (winner) {
      if (winner.hash === this._user.hash) {
        alert(this._locale.t("You win") + " $" + (roomJson.bet ? roomJson.bet.bettedAmount : 0) + ". " + this._locale.t("Your balance") + ": $" + playerJson.balance)
      } else {
        alert(this._locale.t("You lose") + " $" + (roomJson.bet ? roomJson.bet.betAmount : 0) + ". " + this._locale.t("Your balance") + ": $" + playerJson.balance)
      }
    } else {
      alert(this._locale.t("Draw") + ". " + this._locale.t("Your balance") + ": $" + playerJson.balance)
    }
    this._user.setBalance(playerJson.balance)
    window.location.assign('/rooms')
  }

  destroy() {
    this._socket.off('serverPutMove')
    this._socket.off('serverPutRoomGameOver')
  }

}