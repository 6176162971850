import './App.css';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Login from './routes/Login';
import Room from './routes/Room';
import Rooms from './routes/Rooms';
import CreateRoom from './components/CreateRoom';
import WaitRoom from './routes/WaitRoom';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/rooms" element={<Rooms/>}/>
        <Route path="/create-room" element={<CreateRoom/>}/>
        <Route path="/room/:roomId" element={<Room/>}/>
        <Route path="/wait-room/:roomId" element={<WaitRoom/>}/>
      </Routes>
    </Router>
  )
}

export default App;
