import { useContext, useEffect, useState, ChangeEvent } from "react"
import { Button, Collapse, Form, Modal, Table } from "react-bootstrap"
import UserContext from "../context/UserContext"
import GameServer, { PlayerJson, RoomJson } from "../models/GameServer";
import LocaleContext from "../context/LocaleContext";
import RoomStatus from "../enum/RoomStatus";
import { useNavigate } from "react-router-dom";
import ColumnSortableHeader, { OrderState } from "./ColumnSortableHeader";
import FilterButton from "./FilterButton";

export enum RoomModeFilter {
  Fast,
  Slow,
  All
}

function FindRoom() {

  const locale = useContext(LocaleContext)
  const user = useContext(UserContext)
  const navigate = useNavigate()
  const [rooms, setRooms] = useState<RoomJson[]>([])
  const [joinErrorMessage, setJoinErrorMessage] = useState<string>()
  const [showJoinErrorModal, setShowJoinErrorModal] = useState<boolean>(false)
  const handleCloseJoinErrorModal = () => setShowJoinErrorModal(false)
  const handleShowJoinErrorModal = () => setShowJoinErrorModal(true)
  const [collapsedRooms, setCollapsedRooms] = useState<string[]>([])
  const roomsTableOrderParamsDefault = { players: OrderState.None, bet: OrderState.None }
  const [roomsTableOrderParams, setRoomsTableOrderParams] = useState<{ players: OrderState, bet: OrderState }>(roomsTableOrderParamsDefault)
  const roomsTableFilterParamsDefault = { mode: RoomModeFilter.All }
  const [roomsTableFilterParams, setRoomsTableFilterParams] = useState<{ mode: RoomModeFilter }>(roomsTableFilterParamsDefault)

  const joinToRoomBtn = (roomJson: RoomJson) => {
    if (roomJson.status === RoomStatus.Playing) {
      navigate('/room/' + roomJson.id)
      return
    }
    if (![RoomStatus.WaitingForJoining, RoomStatus.WaitingForReadiness].includes(roomJson.status)) {
      alert(locale.t("Something went wrong"))
      return
    }
    if (roomJson.bet.players.some(p => p.hash === user.hash)) {
      navigate('/wait-room/' + roomJson.id)
      return
    }
    if (window.confirm(locale.t('Are you sure? Bet amount: ') + '$' + roomJson.bet.betAmount)) {
      GameServer.joinToRoom(roomJson.id, user.hash)
    }
  }
  const getModeName = (minutes: number) => {
    return (minutes / 15) === 1 ? locale.t('Fast') : locale.t('Slow')
  }
  const onServerPutAvailableRooms = (rooms: RoomJson[]) => {
    setRooms(rooms)
  }
  const onServerPutJoinToRoomError = (errorMessage: string) => {
    setJoinErrorMessage(locale.t(errorMessage))
    handleShowJoinErrorModal()
  }
  const onSlowModeChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let tableFilterParams = {...roomsTableFilterParamsDefault}
    tableFilterParams.mode = Number(e.target.value)
    setRoomsTableFilterParams(tableFilterParams)
  }
  const minutesToFilterMode = (minutes: number) => {
    switch (minutes / 15) {
      case 1:
        return RoomModeFilter.Fast
      case 2:
        return RoomModeFilter.Slow
      default:
        return RoomModeFilter.All
    }
  }
  const onServerPutJoinedNewPlayer = (playerJson: PlayerJson, roomJson: RoomJson) => {
    if (playerJson.hash === user.hash) {
      navigate('/wait-room/' + roomJson.id)
    }
  }

  useEffect(() => {
    GameServer.subscribeOnServerPutAvailableRooms(onServerPutAvailableRooms)
    GameServer.subscribeOnServerPutJoinToRoomError(onServerPutJoinToRoomError)
    GameServer.subscribeOnServerPutJoinedNewPlayer(onServerPutJoinedNewPlayer)
    GameServer.getAvailableRooms()
    return () => {
      GameServer.unsubscribeOnServerPutAvailableRooms()
      GameServer.unsubscribeOnServerPutJoinToRoomError()
      GameServer.unsubscribeOnServerPutJoinedNewPlayer()
    }
  }, [])
  useEffect(() => {
    setRooms([...rooms].sort((a, b) => {
      if (roomsTableOrderParams.bet !== OrderState.None) {
        if (a.bet.bettedAmount === b.bet.bettedAmount) return 0
        return roomsTableOrderParams.bet === OrderState.Ascendant && a.bet.bettedAmount > b.bet.bettedAmount ? 1 : -1
      }
      if (roomsTableOrderParams.players !== OrderState.None) {
        if (a.playersCountMax === b.playersCountMax) return 0
        return roomsTableOrderParams.players === OrderState.Ascendant && a.playersCountMax > b.playersCountMax ? 1 : -1
      }
      return 0
    }))
  }, [roomsTableOrderParams])

  return (
    <>
      <div className="container my-3">
        {rooms.length ? [
          <Form style={{ fontSize: '0.7rem'}}>
            <Form.Group className="mb-2">
              <div className="row">
                <div className="col-auto">
                <FilterButton key={Math.random()} title={locale.t('Bet')} state={roomsTableOrderParams.bet} onClick={() => {
                    let tableOrderParams = {...roomsTableOrderParamsDefault}
                    tableOrderParams.bet = roomsTableOrderParams.bet === 2 ? 0 : roomsTableOrderParams.bet + 1
                    setRoomsTableOrderParams(tableOrderParams)
                }} />
                </div>
              </div>
            </Form.Group>
          </Form>,
          <Table striped hover responsive size="sm" style={{ fontSize: '0.5rem'}}>
            <thead>
              <tr>
                <th key={Math.random()}>{locale.t('Room name')}</th>
                <th key={Math.random()}><ColumnSortableHeader title={locale.t('Bet')} orderState={roomsTableOrderParams.bet} onClick={() => {
                  let tableOrderParams = {...roomsTableOrderParamsDefault}
                  tableOrderParams.bet = roomsTableOrderParams.bet === 2 ? 0 : roomsTableOrderParams.bet + 1
                  setRoomsTableOrderParams(tableOrderParams)
                }}/></th>
                <th key={Math.random()}></th>
              </tr>
            </thead>
            <tbody>
              {rooms.filter(r => {
                if (roomsTableFilterParams.mode === RoomModeFilter.All) return true
                return minutesToFilterMode(r.minutes) === roomsTableFilterParams.mode
              }).map(room => {
                return [
                  <tr key={room.id + '_1'}>
                    <td>{room.name}</td>
                    <td>${room.bet.betAmount}</td>
                    <td>
                      <Button size="sm" variant="secondary" role={"button"} onClick={() => {
                        if (collapsedRooms.includes(room.id)) {
                          setCollapsedRooms(collapsedRooms.filter(r => r !== room.id))
                        } else {
                          setCollapsedRooms([...collapsedRooms, room.id])
                        }
                      }}>
                        <ColumnSortableHeader title="" orderState={!collapsedRooms.includes(room.id) ? OrderState.Ascendant : OrderState.Descendant} onClick={() => {}}/>
                      </Button>
                    </td>
                  </tr>,
                  <Collapse key={room.id + '_2'} in={collapsedRooms.includes(room.id)}>
                    <tr>
                      <td colSpan={2}>
                        <span>{room.description}</span>
                      </td>
                      <td colSpan={3}><Button size="sm" variant={!room.bet.players.some(p => p.hash === user.hash) ? "success" : "warning"} className="w-100" role={"button"} onClick={() => joinToRoomBtn(room)}>{!room.bet.players.some(p => p.hash === user.hash) ? locale.t('Join') : locale.t('Return')}</Button></td>
                    </tr>
                  </Collapse>
                ]
              })}
            </tbody>
          </Table>
        ] : (
          <p className="my-5 text-center">{locale.t('no rooms')}</p>
        )}
      </div>

      <Modal show={showJoinErrorModal} onHide={handleCloseJoinErrorModal}>
        <Modal.Header closeButton>
          <Modal.Title>{locale.t('Error')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{joinErrorMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" size="sm" role={"button"} onClick={handleCloseJoinErrorModal}>
            {locale.t('Close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )

}

export default FindRoom